import React from 'react'
import {Link, useNavigate} from "react-router-dom";
import { useLocation } from 'react-router-dom';
const Navbar = () => {
    let history=useNavigate();
    let location = useLocation();
    const handleLogout=()=>{
        localStorage.removeItem('token')
        history("/login")
    }
    return (
        <>
        <div>
        <nav className="navbar navbar-expand-lg  navbar-dark bg-dark">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/LandingContent">PrivyPad</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <Link className={`nav-link ${location.pathname==="/home"?"active":""}`} aria-current="page" to="/Home">Home</Link>
                </li>
                <li className="nav-item">
                <Link className={`nav-link ${location.pathname==="/about"?"active":""}`} aria-current="page" to="/About">About</Link>
                </li>
            </ul>
            {!localStorage.getItem('token')?<form className="d-flex" >
                <Link className="btn btn-dark mx-1" to="/login"  role="button">Log in</Link>
                <Link className="btn btn-dark mx-1" to="/signup"  role="button">Sign up</Link>
            </form>:<button className='btn btn-dark' onClick={handleLogout}>Logout</button>}
            </div>
        </div>
    </nav>
    </div>
    </>
    )
}
export default Navbar
